//@author: migrated by kate

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { VariableI } from "../../pojo/VariableI";
import { S25BpeVarsDropdownComponent } from "../s25-dropdown/single-select/s25.bpe.vars.dropdown.component";
import { S25Help } from "../s25-help/s25.help.service";

@TypeManagerDecorator("bpe-vars")
@Component({
    selector: "bpe-vars",
    template: `
        <div class="ngBpeVars">
            <p>
                Select event details you would like to include and copy the generated variable values into your
                {{ codeMode ? "code" : "template" }}. Clicking a variable link will copy that value to your clipboard.
            </p>

            <label class="dropdownLabel">Standard Event Variables</label>
            <s25-ng-info-message> Basic variables available in all events. </s25-ng-info-message>
            <s25-bpe-vars-dropdown
                class="c-margin-top--quarter"
                [(chosen)]="selectedVar"
                (chosenChange)="onVarSelect()"
            ></s25-bpe-vars-dropdown>

            <div *ngIf="selectedVar">
                <ng-container
                    [ngTemplateOutlet]="copy"
                    [ngTemplateOutletContext]="{ label: 'Event Variable', text: templateVariable }"
                ></ng-container>
                <ng-container
                    *ngIf="type !== 'document' && !this.hidePreVariable"
                    [ngTemplateOutlet]="copy"
                    [ngTemplateOutletContext]="{ label: 'Pre Event Variable', text: preTemplateVariable }"
                ></ng-container>
            </div>

            <label class="dropdownLabel">Event Roles</label>
            <s25-ng-info-message>
                Functions contacts may perform for an event (such as Emergency Contact, Facilitator, etc.). Select the
                role and the information about it you wish to display.
            </s25-ng-info-message>
            <s25-event-role-dropdown
                class="c-margin-top--quarter"
                [(chosen)]="selectedRole"
                (chosenChange)="onRoleSelect()"
            ></s25-event-role-dropdown>

            <div *ngIf="selectedRole" class="extraInput">
                <label>
                    <span class="selectedVarLabel">Variable: </span>
                    <s25-bpe-vars-dropdown
                        type="roles"
                        [(chosen)]="selectedRoleVar"
                        (chosenChange)="onRoleVarSelect()"
                    ></s25-bpe-vars-dropdown>
                </label>
            </div>
            <div *ngIf="selectedRole && selectedRoleVar">
                <ng-container
                    [ngTemplateOutlet]="copy"
                    [ngTemplateOutletContext]="{ label: 'Role Variable', text: roleVariable }"
                ></ng-container>
                <ng-container
                    *ngIf="type !== 'document'"
                    [ngTemplateOutlet]="copy"
                    [ngTemplateOutletContext]="{ label: 'Pre Role Variable', text: preRoleVariable }"
                ></ng-container>
            </div>

            <label class="dropdownLabel">Custom Attributes</label>
            <s25-ng-info-message>
                Custom data fields used to define additional information for events. Event custom attributes are
                associated with individual event types.
            </s25-ng-info-message>
            <s25-custom-attr-dropdown
                class="c-margin-top--quarter"
                [itemTypeId]="1"
                [(chosen)]="selectedAttribute"
                (chosenChange)="onCustAtrbSelect(selectedAttribute)"
                [whitelistedTypes]="['F', 'B', 'S', 'D', 'E', '6', '3', 'N', '4', 'X', 'T', 'R', '2']"
            ></s25-custom-attr-dropdown>

            <div *ngIf="selectedAttribute">
                <ng-container
                    [ngTemplateOutlet]="copy"
                    [ngTemplateOutletContext]="{ label: 'Custom Attribute', text: selectedAttribute.templateString }"
                ></ng-container>
                <ng-container
                    *ngIf="type !== 'document'"
                    [ngTemplateOutlet]="copy"
                    [ngTemplateOutletContext]="{
                        label: 'Pre Custom Attribute',
                        text: selectedAttribute.preTemplateString,
                    }"
                ></ng-container>
            </div>

            <ng-container *ngIf="!codeMode">
                <label class="dropdownLabel">Formattable Date Variables</label>
                <s25-ng-info-message>
                    Select the desired variable then select a date or time format. A preview will show the display
                    format.
                    <a href="{{ templateHelpLink }}" s25Help [helpTopic]="'template_vars_date'"
                        >Read more about date modification.</a
                    >
                </s25-ng-info-message>
                <s25-bpe-vars-dropdown
                    class="c-margin-top--quarter"
                    [(chosen)]="selectedDateVar"
                    type="dates"
                    (chosenChange)="onDateVarSelect()"
                ></s25-bpe-vars-dropdown>
            </ng-container>

            <div *ngIf="selectedDateVar" class="extraInput">
                <label>
                    <span class="selectedVarLabel">Format: </span>
                    <s25-bpe-vars-dropdown
                        type="dateFormats"
                        [(chosen)]="selectedDateFormat"
                        (chosenChange)="onDateVarSelect()"
                    ></s25-bpe-vars-dropdown>
                </label>
            </div>
            <div *ngIf="selectedDateVar && selectedDateFormat">
                <span>Format Example: {{ formattedDateExample }}</span>
                <ng-container
                    [ngTemplateOutlet]="copy"
                    [ngTemplateOutletContext]="{ label: 'Date Variable', text: dateVariable }"
                ></ng-container>
                <ng-container
                    *ngIf="type !== 'document'"
                    [ngTemplateOutlet]="copy"
                    [ngTemplateOutletContext]="{ label: 'Pre Date Variable', text: preDateVariable }"
                ></ng-container>
            </div>

            <div class="tableBuilder" *ngIf="tableBuilder && isToDoTemplate !== 1 && !codeMode">
                <label class="dropdownLabel">Table Builder</label>
                <s25-ng-info-message>
                    Create a custom table to present desired data from the event.
                </s25-ng-info-message>
                <s25-bpe-vars-dropdown
                    class="c-margin-top--quarter"
                    [(chosen)]="tableBuilder.type"
                    [type]="type === 'email' ? 'tables' : 'documentTables'"
                    (chosenChange)="onTableTypeSelect()"
                ></s25-bpe-vars-dropdown>

                <ng-container *ngIf="tableBuilder.type?.val === 'occurrences'">
                    <s25-ng-checkbox
                        [(modelValue)]="tableBuilder.includeLocations"
                        (modelValueChange)="updateTableKey(); updateTableVar()"
                        >Include Locations</s25-ng-checkbox
                    ><br />
                    <s25-ng-checkbox
                        [(modelValue)]="tableBuilder.includeResources"
                        (modelValueChange)="updateTableKey(); updateTableVar()"
                        >Include Resources</s25-ng-checkbox
                    ><br />
                    <s25-ng-checkbox
                        *ngIf="tableBuilder.includeLocations || tableBuilder.includeResources"
                        [(modelValue)]="tableBuilder.isPref"
                        (modelValueChange)="updateTableKey(); updateTableVar()"
                        >Pref Variables</s25-ng-checkbox
                    >
                </ng-container>
                <div *ngIf="tableBuilder.type?.val && tableBuilder.type?.val !== 'none'">
                    <s25-ng-table-builder
                        [(columns)]="tableBuilder.columns"
                        [variableType]="tableBuilder.variablesKey"
                        [dateFormatType]="type === 'email' ? 'tableDateFormats' : 'tableDocumentDateFormats'"
                        [hasDataColumn]="tableBuilder.type.hasFormat"
                        [hasWidthColumn]="type === 'document'"
                        [dataColumnName]="'Format'"
                        (columnsChange)="updateTableVar()"
                    >
                    </s25-ng-table-builder>

                    <div *ngIf="tableBuilder.type.val && tableBuilder.templateVariable">
                        <ng-container
                            *ngIf="!tableBuilder.isPref"
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{ label: 'Table Variable', text: tableBuilder.templateVariable }"
                        ></ng-container>
                        <ng-container
                            *ngIf="type !== 'document' && !tableBuilder.isPref"
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{
                                label: 'Pre Variable',
                                text: tableBuilder.templatePreVariable,
                            }"
                        ></ng-container>
                        <ng-container
                            *ngIf="tableBuilder.hasPref && tableBuilder.isPref"
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{
                                label: 'Pref Variable',
                                text: tableBuilder.templatePrefVariable,
                            }"
                        ></ng-container>
                        <ng-container
                            *ngIf="tableBuilder.hasPref && tableBuilder.isPref && type !== 'document'"
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{
                                label: 'PrePref Variable',
                                text: tableBuilder.templatePrePrefVariable,
                            }"
                        ></ng-container>
                    </div>
                </div>
            </div>

            <div class="tableBuilder" *ngIf="listBuilder && isToDoTemplate !== 1 && !codeMode">
                <label class="dropdownLabel">List Builder</label>
                <s25-ng-info-message>
                    Create a custom list to present desired data from the event.
                </s25-ng-info-message>
                <s25-bpe-vars-dropdown
                    class="c-margin-top--quarter"
                    [(chosen)]="listBuilder.type"
                    type="tables"
                    (chosenChange)="onListTypeSelect()"
                ></s25-bpe-vars-dropdown>

                <label *ngIf="listBuilder.type?.val && listBuilder.type.val !== 'none'">
                    <span class="selectedVarLabel">Variable: </span>
                    <s25-bpe-vars-dropdown
                        [type]="$any(listBuilder.type.val)"
                        [(chosen)]="listBuilder.variable"
                        (chosenChange)="onListVariableChange()"
                    ></s25-bpe-vars-dropdown>
                </label>
                <label *ngIf="listBuilder.variable?.hasFormat">
                    <span class="selectedVarLabel">Format: </span>
                    <s25-bpe-vars-dropdown
                        type="tableDateFormats"
                        [(chosen)]="listBuilder.format"
                        (chosenChange)="updateListVar()"
                    ></s25-bpe-vars-dropdown>
                </label>
                <div *ngIf="listBuilder.type?.val && listBuilder.variable?.val && listBuilder.templateVariable">
                    <ng-container
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{ label: 'List Variable', text: listBuilder.templateVariable }"
                    ></ng-container>
                    <ng-container
                        *ngIf="type !== 'document'"
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{
                            label: 'Pre Variable',
                            text: listBuilder.templatePreVariable,
                        }"
                    ></ng-container>
                    <ng-container
                        *ngIf="listBuilder.hasPref"
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{
                            label: 'Pref Variable',
                            text: listBuilder.templatePrefVariable,
                        }"
                    ></ng-container>
                    <ng-container
                        *ngIf="listBuilder.hasPref && type !== 'document'"
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{
                            label: 'PrePref Variable',
                            text: listBuilder.templatePrePrefVariable,
                        }"
                    ></ng-container>
                </div>
            </div>

            <div *ngIf="type === 'document'" class="documentVariables">
                <h3>Document Variables</h3>
                <label class="dropdownLabel">General Variables</label>
                <s25-ng-info-message>
                    Special variables for information tables and a variety of other data groupings.
                </s25-ng-info-message>
                <s25-bpe-vars-dropdown
                    class="c-margin-top--quarter"
                    type="wsVars"
                    [(chosen)]="selectedWsVar"
                    (chosenChange)="onWsVarSelect(selectedWsVar)"
                ></s25-bpe-vars-dropdown>

                <div *ngIf="selectedWsVar">
                    <ng-container
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{ label: 'Variable', text: selectedWsVar.templateString }"
                    ></ng-container>
                </div>
                <div *ngIf="docScope === 'event'">
                    <label class="dropdownLabel">Event Variables</label>
                    <s25-ng-info-message>
                        Provides information about resource totals, locations, and other information pertaining to the
                        whole event rather than individual occurrences.
                    </s25-ng-info-message>
                    <s25-bpe-vars-dropdown
                        class="c-margin-top--quarter"
                        type="wsEventVars"
                        [(chosen)]="selectedWsEventVar"
                        (chosenChange)="onWsVarSelect(selectedWsEventVar)"
                    ></s25-bpe-vars-dropdown>

                    <div *ngIf="selectedWsEventVar">
                        <ng-container
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{
                                label: 'Variable',
                                text: selectedWsEventVar.templateString,
                            }"
                        ></ng-container>
                    </div>
                    <label class="dropdownLabel">Related Event Variables</label>
                    <s25-bpe-vars-dropdown
                        type="wsRelatedEventVars"
                        [(chosen)]="selectedWsRelatedEventVar"
                        (chosenChange)="onWsVarSelect(selectedWsRelatedEventVar)"
                    ></s25-bpe-vars-dropdown>

                    <div *ngIf="selectedWsRelatedEventVar">
                        <ng-container
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{
                                label: 'Variable',
                                text: selectedWsRelatedEventVar.templateString,
                            }"
                        ></ng-container>
                    </div>
                </div>

                <label class="dropdownLabel">Organization Variables</label>
                <s25-ng-info-message>
                    Variables containing information about the primary organization of the event.
                </s25-ng-info-message>
                <s25-bpe-vars-dropdown
                    class="c-margin-top--quarter"
                    type="wsOrgVars"
                    [(chosen)]="selectedWsOrgVar"
                    (chosenChange)="onWsVarSelect(selectedWsOrgVar)"
                ></s25-bpe-vars-dropdown>
                <div *ngIf="selectedWsOrgVar">
                    <ng-container
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{
                            label: 'Variable',
                            text: selectedWsOrgVar.templateString,
                        }"
                    ></ng-container>
                </div>

                <label class="dropdownLabel">Organization Custom Attributes</label>
                <s25-ng-info-message>
                    Custom data fields used to define additional information for organizations. Organization custom
                    attributes are associated with individual organization types.
                </s25-ng-info-message>
                <s25-custom-attr-dropdown
                    class="c-margin-top--quarter"
                    [itemTypeId]="2"
                    [(chosen)]="selectedOrgAttribute"
                    (chosenChange)="onOrgCustAtrbSelect(selectedOrgAttribute)"
                    [whitelistedTypes]="['F', 'B', 'S', 'D', 'E', '6', '3', 'N', '4', 'X', 'T', 'R', '2']"
                ></s25-custom-attr-dropdown>

                <label class="dropdownLabel">Location & Resource Occurrences</label>
                <s25-ng-info-message> Occurrence tables for locations and resources. </s25-ng-info-message>
                <s25-bpe-vars-dropdown
                    class="c-margin-top--quarter"
                    [type]="'wsLocResOccTables'"
                    [(chosen)]="selectedWsLocResTableVar"
                    (chosenChange)="onWsVarSelect(selectedWsLocResTableVar)"
                ></s25-bpe-vars-dropdown>

                <div *ngIf="selectedWsLocResTableVar">
                    <ng-container
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{
                            label: 'Variable',
                            text: selectedWsLocResTableVar.templateString,
                        }"
                    ></ng-container>
                </div>

                <label class="dropdownLabel">Location & Resource Occurrence Pricing</label>
                <s25-ng-info-message>
                    Tables containing pricing for location and resource occurrences.
                </s25-ng-info-message>
                <s25-bpe-vars-dropdown
                    class="c-margin-top--quarter"
                    [type]="'wsLocResOccPricingTables'"
                    [(chosen)]="selectedWsLocResOccPricingVar"
                    (chosenChange)="onWsVarSelect(selectedWsLocResOccPricingVar)"
                ></s25-bpe-vars-dropdown>

                <div *ngIf="selectedWsLocResOccPricingVar">
                    <ng-container
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{
                            label: 'Variable',
                            text: selectedWsLocResOccPricingVar.templateString,
                        }"
                    ></ng-container>
                </div>

                <div *ngIf="selectedOrgAttribute">
                    <ng-container
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{
                            label: 'Variable',
                            text: selectedOrgAttribute.templateString,
                        }"
                    ></ng-container>
                </div>
                <div *ngIf="docScope === 'reservation'">
                    <label class="dropdownLabel">Reservation Variables</label>
                    <s25-ng-info-message>
                        Information about specific occurrences and segments of an event.
                    </s25-ng-info-message>
                    <s25-bpe-vars-dropdown
                        class="c-margin-top--quarter"
                        type="wsRsrVars"
                        [(chosen)]="selectedWsRsrVar"
                        (chosenChange)="onWsVarSelect(selectedWsRsrVar)"
                    ></s25-bpe-vars-dropdown>

                    <div *ngIf="selectedWsRsrVar">
                        <ng-container
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{
                                label: 'Variable',
                                text: selectedWsRsrVar.templateString,
                            }"
                        ></ng-container>
                    </div>
                    <label class="dropdownLabel">Reservation Formattable Date Variables</label>
                    <s25-ng-info-message>
                        Select the desired variable then select a date or time format. A preview will show the display
                        format.
                        <a href="{{ documentHelpLink }}" s25Help [helpTopic]="'document_vars_date'">
                            Read more about date modification.</a
                        >
                    </s25-ng-info-message>
                    <s25-bpe-vars-dropdown
                        class="c-margin-top--quarter"
                        type="wsRsrDateVars"
                        [(chosen)]="selectedWsRsvDateVar"
                        (chosenChange)="onWsDateVarSelect(selectedWsRsvDateVar)"
                    ></s25-bpe-vars-dropdown>

                    <div *ngIf="selectedWsRsvDateVar" class="extraInput">
                        <label>
                            <span class="selectedVarLabel">Format: </span>
                            <s25-bpe-vars-dropdown
                                type="dateFormats"
                                [(chosen)]="selectedWsDateFormat"
                                (chosenChange)="onWsDateVarSelect(selectedWsRsvDateVar)"
                            ></s25-bpe-vars-dropdown>
                        </label>
                    </div>
                    <div *ngIf="selectedWsRsvDateVar && selectedWsDateFormat">
                        <span>Format Example: {{ selectedWsDateEx }}</span>
                        <ng-container
                            [ngTemplateOutlet]="copy"
                            [ngTemplateOutletContext]="{
                                label: 'Variable',
                                text: selectedWsRsvDateVar.templateString,
                            }"
                        ></ng-container>
                    </div>
                </div>
                <div>
                    <label class="dropdownLabel">Resources by Id</label>
                    <s25-ng-info-message>
                        Determine if you want to "Include All" then optionally check the box. Use the "Select Resources"
                        button to choose one or more resources. Then, choose display options.
                    </s25-ng-info-message>
                    <div class="c-margin-bottom--single c-margin-top--half">
                        <s25-ng-checkbox [(modelValue)]="allResources" (modelValueChange)="onAllResourceChange()">
                            Include All
                        </s25-ng-checkbox>
                    </div>
                    <s25-ng-multiselect-search-criteria
                        [type]="'resources'"
                        [modelBean]="resourceBean"
                        [popoverOnBody]="true"
                    ></s25-ng-multiselect-search-criteria>
                    <p class="c-margin-top--single">{{ resourceNameString }}</p>
                </div>

                <div *ngIf="(resourceIdString && resourceIdString.length > 0) || allResources">
                    <label class="c-margin-top--half">
                        <span class="selectedVarLabel">Display Options: </span>
                        <s25-bpe-vars-dropdown
                            type="wsResourceFormats"
                            [(chosen)]="selectedResourceWsVar"
                            (chosenChange)="onWsResourceVarSelect()"
                        ></s25-bpe-vars-dropdown>
                    </label>
                    <ng-container
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{
                            label: 'Variable',
                            text: selectedResourceWsVar?.templateString,
                        }"
                    ></ng-container>
                </div>
                <div>
                    <label class="dropdownLabel">Resources by Category</label>
                    <s25-ng-info-message>
                        Determine if you want to "Include All" then optionally check the box. Use the "Select
                        Categories" button to choose one or more categories. Then, choose display options.
                    </s25-ng-info-message>
                    <div class="c-margin-bottom--single c-margin-top--half">
                        <s25-ng-checkbox [(modelValue)]="allResCats" (modelValueChange)="onAllResCatsChange()">
                            Include All
                        </s25-ng-checkbox>
                    </div>
                    <s25-ng-multiselect-search-criteria
                        [type]="'resourceCategories'"
                        [modelBean]="resCatBean"
                        [popoverOnBody]="true"
                    ></s25-ng-multiselect-search-criteria>
                    <p class="c-margin-top--single">{{ resCatNameString }}</p>
                </div>

                <div *ngIf="(resCatString && resCatString.length > 0) || allResCats">
                    <label class="c-margin-top--half">
                        <span class="selectedVarLabel">Display Options: </span>
                        <s25-bpe-vars-dropdown
                            type="wsResourceFormats"
                            [(chosen)]="selectedResCatWsVar"
                            (chosenChange)="onWsResCatVarSelect()"
                        ></s25-bpe-vars-dropdown>
                    </label>
                    <ng-container
                        [ngTemplateOutlet]="copy"
                        [ngTemplateOutletContext]="{
                            label: 'Variable',
                            text: selectedResCatWsVar?.templateString,
                        }"
                    ></ng-container>
                </div>
            </div>

            <ng-template #copy let-text="text" let-label="label">
                <label class="copy">
                    <span class="selectedVarLabel">{{ label }}: </span>
                    <a
                        class="c-textButton tooltipButton"
                        title="Copy to Clipboard"
                        aria-label="copy event variable to clipboard"
                        (click)="copyTextToClipBoard(text)"
                        (keyup.enter)="copyTextToClipBoard(text)"
                        tabindex="0"
                    >
                        {{ text }}
                        <span class="tooltipBefore">Copy to Clipboard</span>
                        <span class="tooltipAfter">Copied!</span>
                    </a>
                </label>
            </ng-template>
        </div>
    `,
    styles: `
        .ngBpeVars .dropdownLabel {
            display: inline-block !important;
            font-weight: bold;
            margin-top: 1em;
            margin-bottom: 0;
            width: min(100%, 225px);
        }

        .extraInput {
            margin-top: 0.5em;
        }

        .copy {
            margin-top: 0.5em;
            display: block;
        }

        .documentVariables {
            margin-top: 1em;
            border-top: 1px solid rgba(255, 255, 255, 0.24);
            padding-top: 1em;
        }
    `,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class BpeVarsComponent implements OnInit, OnChanges {
    //Document Management of Event Save Emails
    @Input() type: string = "email";
    //In document management, is the report scoped to the event or reservation
    @Input() docScope: string = "event";
    @Input() isToDoTemplate?: number = 0;
    @Input() codeMode: boolean;

    selectedAttribute: VariableI;
    selectedOrgAttribute: VariableI;
    selectedVar: VariableI;
    selectedRoleVar: VariableI;
    selectedDateFormat: VariableI;
    selectedDateVar: VariableI;
    selectedRole: VariableI;
    selectedWorkflowVar: VariableI;
    //ws variables used in document management
    selectedWsVar: VariableI;
    selectedResourceWsVar: VariableI;
    selectedResCatWsVar: VariableI;
    selectedWsEventVar: VariableI;
    selectedWsRsrVar: VariableI;
    selectedWsRsvDateVar: VariableI;
    selectedWsDateFormat: VariableI;
    selectedWsOrgVar: VariableI;
    selectedWsLocResTableVar: VariableI;
    selectedWsLocResOccPricingVar: VariableI;
    selectedWsRelatedEventVar: VariableI;
    selectedWsDateEx: string = "";
    tableBuilder: {
        type?: VariableI;
        includeLocations?: boolean; // Only for occurrences
        includeResources?: boolean; // Only for occurrences
        isPref?: boolean; // Only for occurrences
        hasPref?: boolean;
        variablesKey?: keyof typeof S25BpeVarsDropdownComponent.Items;
        templateVariable?: string;
        templatePreVariable?: string;
        templatePrefVariable?: string;
        templatePrePrefVariable?: string;
        columns: {
            header: string;
            math?: string;
            format?: VariableI;
            variable?: VariableI;
            width?: number;
        }[];
    } = { columns: [{ header: "New Column", width: 1 }] };
    listBuilder: {
        type?: VariableI;
        variable?: VariableI;
        format?: VariableI;
        hasPref?: boolean;
        templateVariable?: string;
        templatePreVariable?: string;
        templatePrefVariable?: string;
        templatePrePrefVariable?: string;
    } = {};
    resourceIdString: string = "";
    resourceNameString: string = "";
    resourceBean: any = {};
    resCatString: string = "";
    resCatNameString: string = "";
    resCatBean: any = {};
    date = new Date();
    allResources: boolean = false;
    allResCats: boolean = false;
    //TODO: refactor these to just use the VariableI properties
    templateVariable: any;
    preTemplateVariable: any;
    dateVariable: any;
    preDateVariable: any;
    formattedDateExample: any;
    workflowVariable: any;
    roleVariable: any;
    preRoleVariable: any;
    templateHelpLink = S25Help.getHelpUrl("template_vars_date");
    documentHelpLink = S25Help.getHelpUrl("document_vars_date");

    hidePreVariable: boolean = false;
    hidePreVariableList: string[] = ["eventId", "eventLink", "eventUrl", "eventLocator", "homeUrl"];

    convertToPre(val: string) {
        return "pre" + val.charAt(0).toUpperCase() + val.substring(1);
    }

    copyTextToClipBoard(text: string) {
        S25Util.copyToClipboard(text);
    }

    wrapVar(variable: string, index?: string | number) {
        const i = index || Number(index) === 0 ? (this.codeMode ? `[${index}]` : `.${index}`) : "";
        if (this.codeMode) return `${variable}${i}`;
        return `{{${variable}${i}}}`;
    }

    onCustAtrbSelect(tvar: VariableI) {
        tvar.templateString = this.wrapVar("$pro.vars.customAttributes", tvar.itemId);
        tvar.preTemplateString = this.wrapVar("$pro.vars.preCustomAttributes", tvar.itemId);
    }

    onOrgCustAtrbSelect(tvar: VariableI) {
        tvar.templateString = this.wrapVar("ws.org.CustomAttribute", tvar.itemId);
    }

    onRoleSelect() {
        if (this.selectedRoleVar) {
            this.onRoleVarSelect();
        }
    }

    onVarSelect() {
        this.templateVariable = this.wrapVar("$pro.vars." + this.selectedVar.val);
        this.preTemplateVariable = this.wrapVar("$pro.vars." + this.convertToPre(this.selectedVar.val));
        this.type === "email" && this.hidePreVariableList.includes(this.selectedVar.val)
            ? (this.hidePreVariable = true)
            : (this.hidePreVariable = false);
    }

    onWsVarSelect(tvar: VariableI) {
        tvar.templateString = this.wrapVar(tvar.val);
    }

    onAllResourceChange() {
        if (this.allResources) {
            this.resourceNameString = "";
            this.resourceIdString = "ALL";
        }
        this.onWsResourceVarSelect();
    }

    onAllResCatsChange() {
        if (this.allResCats) {
            this.resCatNameString = "";
            this.resCatString = "ALL";
        }
        this.onWsResCatVarSelect();
    }

    onWsResourceVarSelect() {
        //{{ws.rsrv.RsrcName_Inst-ID.###,###,###}}
        if (this.selectedResourceWsVar) {
            this.selectedResourceWsVar.templateString = this.wrapVar(
                this.selectedResourceWsVar.val + "-ID",
                this.resourceIdString,
            );
        }
        this.cd.detectChanges();
    }

    onWsResCatVarSelect() {
        //{{ws.rsrv.RsrcName_Inst-Cat.###,###,###}}
        if (this.selectedResCatWsVar) {
            this.selectedResCatWsVar.templateString = this.wrapVar(
                this.selectedResCatWsVar.val + "-Cat",
                this.resCatString,
            );
        }
        this.cd.detectChanges();
    }

    onDateVarSelect() {
        if (this.selectedDateVar) {
            this.dateVariable = this.wrapVar(
                "$pro.vars.dates." +
                    this.selectedDateVar.val +
                    (this.selectedDateFormat ? " | " + this.selectedDateFormat.val : ""),
            );
            this.preDateVariable = this.wrapVar(
                "$pro.vars.preDates." +
                    this.selectedDateVar.val +
                    (this.selectedDateFormat ? " | " + this.selectedDateFormat.val : ""),
            );
            if (this.selectedDateFormat) {
                this.formattedDateExample = this.selectedDateFormat.ex;
            }
        }
    }

    onWsDateVarSelect(dVar: VariableI) {
        dVar.templateString = this.wrapVar(
            dVar.val + (this.selectedWsDateFormat ? "|" + this.selectedWsDateFormat.val : ""),
        );
        if (this.selectedWsDateFormat) {
            this.selectedWsDateEx = this.selectedWsDateFormat.ex;
        }
    }

    onRoleVarSelect() {
        if (this.selectedRole) {
            this.roleVariable = this.wrapVar("$pro.vars." + this.selectedRoleVar.val, this.selectedRole.itemId);
            this.preRoleVariable = this.wrapVar(
                "$pro.vars." + this.convertToPre(this.selectedRoleVar.val),
                this.selectedRole.itemId,
            );
        } else {
            alert("Please select a role first.");
        }
    }

    onWorkflowVarSelect() {
        this.workflowVariable = this.wrapVar("$pro.vars." + this.selectedWorkflowVar.val);
        // this.preWorkflowVariable = "{{$pro.vars." + this.convertToPre(this.selectedWorkflowVar.val) + "}}";
    }

    onTableTypeSelect() {
        const { type } = this.tableBuilder;
        const variablesKey = type.val as keyof typeof S25BpeVarsDropdownComponent.Items;
        this.tableBuilder = {
            type,
            variablesKey,
            hasPref: ["locations", "resources"].includes(type.val),
            columns: this.getDefaultTableColumns(variablesKey),
        };
        this.updateTableVar();
        this.cd.detectChanges();
    }

    getDefaultTableColumns(variablesKey: keyof typeof S25BpeVarsDropdownComponent.Items) {
        if (this.tableBuilder.type.val === "none") return [];
        return (S25BpeVarsDropdownComponent.Items[variablesKey] as any[]).map((item) => ({
            header: item.txt,
            variable: item,
            width: item.width,
        }));
    }

    onAddColumn() {
        this.tableBuilder.columns.push({ header: "New Column", width: 1 });
        this.cd.detectChanges();
    }

    onDeleteColumn(i: number) {
        this.tableBuilder.columns.splice(i, 1);
        this.updateTableVar();
        this.cd.detectChanges();
    }

    updateTableKey() {
        const { type, includeResources, includeLocations } = this.tableBuilder;
        let key = type.val as keyof typeof S25BpeVarsDropdownComponent.Items;
        // includeLocations and includeResources are only present on occurrences
        this.tableBuilder.hasPref = key === "locations" || key === "resources" || includeResources || includeLocations;
        this.tableBuilder.isPref = this.tableBuilder.hasPref && this.tableBuilder.isPref;
        if (includeLocations) key += "Locations";
        if (includeResources) key += "Resources";
        if (this.tableBuilder.isPref && (includeLocations || includeResources)) key += "Pref";
        this.tableBuilder.variablesKey = key;
        this.tableBuilder.columns = this.getDefaultTableColumns(key);
        this.cd.detectChanges();
    }

    updateTableVar() {
        // {{for:$pro.vars.occurrences:rsrvState:State||dates.rsrvStartDate|mediumDate:Start||rsrvStateName:State:end-for}}
        const { type, includeResources, includeLocations } = this.tableBuilder;
        const isDocument = this.type === "document";
        const columns = this.tableBuilder.columns
            .filter((column) => !!column.variable?.val)
            .map((column) => {
                const { header, variable, format, width } = column;
                const formatString = format ? ` | ${format.val}` : "";
                const columnWidth = isDocument ? ` : ${width}` : "";
                const variableName = isDocument ? variable.wsVal || variable.val : variable.val;
                return `${variableName}${formatString} : ${header}${columnWidth}`;
            })
            .join(" || ");
        if (columns) {
            let variable = type.val;
            if (type.val === "occurrences" && includeLocations) variable += "Locations";
            if (type.val === "occurrences" && includeResources) variable += "Resources";
            const typeStr = variable.slice(0, 1).toUpperCase() + variable.slice(1);
            const tablePrefix = isDocument ? "ws.flexTable" : "$pro.vars";

            this.tableBuilder.templateVariable = `{{for: ${tablePrefix}.${variable}: ${columns} :end-for}}`;
            this.tableBuilder.templatePreVariable = `{{for: ${tablePrefix}.pre${typeStr}: ${columns} :end-for}}`;
            this.tableBuilder.templatePrefVariable = `{{for: ${tablePrefix}.pref${typeStr}: ${columns} :end-for}}`;
            this.tableBuilder.templatePrePrefVariable = `{{for: ${tablePrefix}.prePref${typeStr}: ${columns} :end-for}}`;
        } else this.tableBuilder.templateVariable = "";
        this.cd.detectChanges();
    }

    onColumnVariableChange(column: any) {
        delete column.format;
        this.updateTableVar();
    }

    onListTypeSelect() {
        this.listBuilder = {
            type: this.listBuilder.type,
            hasPref: ["locations", "resources"].includes(this.listBuilder.type.val),
        };
        this.updateListVar();
        this.cd.detectChanges();
    }

    onListVariableChange() {
        delete this.listBuilder.format;
        this.updateListVar();
    }

    updateListVar() {
        const key = this.listBuilder.type.val;
        const Key = key.slice(0, 1).toUpperCase() + key.slice(1);
        const variable = this.listBuilder.variable?.val;
        const format = this.listBuilder.format?.val ? `| ${this.listBuilder.format?.val} ` : "";
        this.listBuilder.templateVariable = `{{list: $pro.vars.${key} : ${variable} ${format}}}`;
        this.listBuilder.templatePreVariable = `{{list: $pro.vars.pre${Key} : ${variable} ${format}}}`;
        this.listBuilder.templatePrefVariable = `{{list: $pro.vars.pref${Key} : ${variable} ${format}}}`;
        this.listBuilder.templatePrePrefVariable = `{{list: $pro.vars.prePref${Key} : ${variable} ${format}}}`;
        this.cd.detectChanges();
    }

    constructor(
        private elementRef: ElementRef,
        private zone: NgZone,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.docScope) this.cd.detectChanges();
    }

    ngOnInit() {
        this.resourceBean.onDone = () => {
            this.resourceIdString = "";
            for (let r of this.resourceBean.selectedItems) {
                this.resourceNameString = this.resourceNameString + r.itemName + ", ";
                this.resourceIdString = this.resourceIdString + r.itemId + ",";
            }
            //remove trailing comma
            this.resourceIdString = this.resourceIdString.slice(0, -1);
            this.resourceNameString = this.resourceNameString.slice(0, -2);
            this.allResources = false;
            this.onWsResourceVarSelect();
            this.resourceBean.selectedItems = [];
        };

        this.resCatBean.onDone = () => {
            this.resCatString = "";
            for (let r of this.resCatBean.selectedItems) {
                this.resCatNameString = this.resCatNameString + r.itemName + ", ";
                this.resCatString = this.resCatString + r.itemId + ",";
            }
            //remove trailing comma
            this.resCatString = this.resCatString.slice(0, -1);
            this.resCatNameString = this.resCatNameString.slice(0, -2);
            this.allResCats = false;
            this.onWsResCatVarSelect();
            this.resCatBean.selectedItems = [];
        };
    }

    protected readonly S25Help = S25Help;
}
